import { ApiRequest } from "../models/proxy-api-request.js";
import { ApiResponse } from "../models/proxy-api-response.js";
import instance from "./proxy-axios.js";

export const proxyApi = {
    account: {
        getAccountList: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/p1/Account/get_account_list`, request),
    },
    plintron: {
        queryDevice: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Plintron/query_device`, request),
        extMnpCheckPortinEligibility: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Plintron/ext_mnp_check_portin_eligibility`, request),
    },
    sim_card: {
        getCardInfo: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/p1/SIMCard/get_card_info`, request)
    },
    subscription: {
        getSubscriptionList: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/p1/Subscription/get_subscription_list`, request)
    },
    plan: {
        getDefaultPlans: (): Promise<ApiResponse> => instance.post(`/Plans/get_default_plans`),
        getPartnerPlans: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Plans/get_partner_plans`, request),
    },
    checkout: {
        checkout: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Checkout`, request),
        prepaidTotal: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Checkout/prepaid_total`, request),
        completeCheckout: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Checkout/complete`, request),
    },
    transaction: {
        payPal: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Transaction/pay_pal`, request),
        creditCard: (request: ApiRequest): Promise<ApiResponse> => instance.post(`/Transaction/credit_card`, request),
    }
};